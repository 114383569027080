import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: ${({ height }) => height};
  width: 100%;

  background: url(${({ bgImage }) => bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: ${({ paralax }) => (paralax ? "fixed" : "none")};

  @media screen and (max-width: 991px) {
    background-attachment: none;
  }
`;

const FullWidthImageSection = ({ children, bgImage, paralax, height }) => {
  return (
    <>
      <section id="divider-paralax">
        <Wrapper bgImage={bgImage} paralax={paralax} height={height}>
          {children}
        </Wrapper>
      </section>
    </>
  );
};

export default FullWidthImageSection;

FullWidthImageSection.defaultProps = {
  paralax: false,
  height: "400px",
};

FullWidthImageSection.propTypes = {
  children: PropTypes.element,
  bgImage: PropTypes.string.isRequired,
  height: PropTypes.string,
  paralax: PropTypes.bool,
};
